import React, { Component } from 'react';
import Responsive from '../libraries/responsive';
import { Helmet } from 'react-helmet';
import productsData from '../../products';
import images from '../assets/images';
import { Link } from 'gatsby';
import route from '../libraries/route';
import TopMenu from '../components/TopMenu';
import BottomMenu from '../components/BottomMenu';
class Products extends Component {
    render() {
        const { meta_title, meta_description } = productsData.default;
        const products = productsData.default.items
        const fullProducts = products.filter(
            product => product.type === 'full-product'
        );
        const services = products.filter(product => product.type === 'service');
        const components = products.filter(product => product.type === 'component');
        const tools = products.filter(product => product.type === 'tool');
        return (
          <>
            <TopMenu />
            <div className="products">
              <Helmet title={meta_title}>
                <meta name="description" content={meta_description} />
              </Helmet>
              <Responsive device="desktop">
                <main className="products-desktop">
                  <section>
                    <h2>Prodotti</h2>
                    {fullProducts.map(product => (
                      <Link
                        to={route("product", { productID: product.id })}
                        className="product-container"
                        key={product.id}
                      >
                        <img
                          alt={product.id}
                          src={
                            product.images
                              ? images[product.images[0]]
                              : images.favicon
                          }
                        />
                        <div>{product.title || product.id}</div>
                      </Link>
                    ))}
                  </section>
                  <section>
                    <h2>Servizi</h2>
                    {services.map(product => (
                      <div className="product-container" key={product.id}>
                        <img alt={product.id} src={images[product.image]} />
                        <div>{product.title || product.id}</div>
                      </div>
                    ))}
                  </section>
                  <section>
                    <h2>Componenti</h2>
                    {components.map(product => (
                      <div className="product-container" key={product.id}>
                        <div>{product.title || product.id}</div>
                      </div>
                    ))}
                  </section>
                  <section>
                    <h2>Strumenti</h2>
                    {tools.map(product => (
                      <div className="product-container" key={product.id}>
                        <div>{product.title || product.id}</div>
                      </div>
                    ))}
                  </section>
                </main>
              </Responsive>
              <Responsive device="not-desktop">
                <main className="products-mobile">
                  <section>
                    <h2>Prodotti</h2>
                    {fullProducts.map(product => (
                      <Link
                        to={route("product", { productID: product.id })}
                        className="product-container"
                        key={product.id}
                      >
                        <img
                          alt={product.id}
                          src={
                            product.images
                              ? images[product.images[0]]
                              : images.favicon
                          }
                        />
                        <div>{product.title || product.id}</div>
                      </Link>
                    ))}
                  </section>
                  <section>
                    <h2>Servizi</h2>
                    {services.map(product => (
                      <div className="product-container" key={product.id}>
                        <img alt={product.id} src={images[product.image]} />
                        <div>{product.title || product.id}</div>
                      </div>
                    ))}
                  </section>
                  <section>
                    <h2>Componenti</h2>
                    {components.map(product => (
                      <div className="product-container" key={product.id}>
                        <div>{product.title || product.id}</div>
                      </div>
                    ))}
                  </section>
                  <section>
                    <h2>Strumenti</h2>
                    {tools.map(product => (
                      <div className="product-container" key={product.id}>
                        <div>{product.title || product.id}</div>
                      </div>
                    ))}
                  </section>
                </main>
              </Responsive>
            </div>
            <BottomMenu />
          </>
        )
    }
}

export default Products;
