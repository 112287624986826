exports.default = {
  meta_title:
    "Prodotti Lericas - Scope di Erica, Coperture, Strutture in Legno",
  meta_description:
    "Prodotti. Scope in erica. Coperture naturali. Gazebo. Parcheggi Auto. Pergola | Tettoia. Ombrellone. Scala. Staccionata. Recinzione. Pannello frangivento. Cancello.",
  items: [
    {
      id: "scope",
      materials:
        "manico: castagno | faggio, spazzola: erica | saggina | bambù | plastica",
      type: "full-product",
      metaTitle:
        "Lericas - Scope in erica scoparia, bambù, saggina e per ippodromo",
      metaDescription:
        "Scope di Erica - Scope in Bambù  - Scope in Saggina - Scope da Ippodromo - Scope artigianali che spazzano Piazza del Campo a Siena, gioiello Toscano",
      images: ["scope1", "scope2", "scope3", "scope4"],
      title: "Scope in erica",
      description:
        "Siamo orgogliosi del fatto che le nostre scope in erica spazzano la piazza del campo nei giorni del Palio di Siena. Le nostre scope di erica sono le migliori del territorio ma assecondiamo anche le esigenze dei clienti, quindi disponiamo anche di scope in plastica, scope in saggina e scope con manici di bambù.",
    },
    {
      id: "coperture",
      materials: "erica | bamboo | guaina ardesiata",
      type: "full-product",
      metaTitle:
        "Coperture Lericas - Coperture in erica, naturali e artigianali",
      metaDescription:
        "Le nostre coperture in erica sono le migliori, scopri anche le altre coperture natuali fatte in maniera artigianale, con materiale dei boschi toscani.",
      images: ["coperture1", "coperture2", "coperture3", "coperture4"],
      title: "Coperture naturali",
      description:
        "Le nostre coperture in erica sono le migliori, scopri anche le altre coperture naturali fatte in maniera artigianale, con materiale dei boschi toscani. Richiedi un preventivo gratuito!",
    },
    {
      id: "gazebo",
      materials:
        "struttura: castagno | abete | acciaio zincato | ferro forgiato, copertura: erica | pvc | guaina ardesiata",
      type: "full-product",
      metaTitle: "Gazebo Artigianale Lericas - Coperture in Erica",
      metaDescription:
        "Gazebo artigianale in castagno con copertura in erica naturale con materiali della Toscana",
      images: [
        "gazebo1",
        "gazebo2",
        "gazebo3",
        "gazebo4",
        "gazebo5",
        "gazebo6",
        "gazebo7",
        "gazebo8",
        "gazebo9",
        "gazebo10",
        "gazebo11",
        "gazebo12",
        "gazebo13",
      ],
      title: "Gazebo",
      description:
        "I nostri gazebo artigianali in legno, con coperture naturali, sono quello che serve per dare al tuo spazio un design innovativo, senza perdere l'attaccamento alla natura. Abbiamo coperture eleganti come l'erica scoparia ( richieste da molti hotel di lusso ) e affascinanti strutture in legno di castagno, contattaci per scoprire altre personalizzazioni e ricevere un preventivo gratuito.",
    },
    {
      id: "carport",
      materials:
        "struttura: castagno | abete | acciaio zincato | ferro forgiato, copertura: erica | pvc  | guaina ardesiata",
      type: "full-product",
      metaTitle:
        "Parcheggi Auto Lericas - Struttura Legno - Coperture in Erica",
      metaDescription:
        "I nostri parcheggi auto fanno parte delle strutture in legno di castagno con coperture in erica e bambù",
      images: [
        "carport1",
        "carport2",
        "carport3",
        "carport4",
        "carport5",
        "carport6",
      ],
      title: "Parcheggi Auto",
      description:
        "In nostri parcheggi auto artigianali in legno, con coperture naturali, sono il riparo ideale per la tua auto. I materiali naturali forniscono un ottimo riparo e non appesantiscono l'ambiente, contattaci per scoprire tutte le personalizzazioni e ricevere un preventivo gratuito.",
    },
    {
      id: "pergola",
      materials: "castagno | pino impregnato",
      type: "full-product",
      metaTitle:
        "Pergole e Tettoie Lericas - Pergola e Tettoia naturale in erica",
      metaDescription:
        "Le nostre pergole e tettoie artigianali sono un'ottima soluzione per creare uno spazio al riparo, confortevole e con un design naturale.",
      images: [
        "pergola1",
        "pergola2",
        "pergola3",
        "pergola4",
        "pergola5",
        "pergola6",
        "pergola7",
        "pergola8",
      ],
      title: "Pergola | Tettoia",
      description:
        "Le nostre pergole e tettoie artigianali sono un'ottima soluzione per creare uno spazio al riparo, confortevole e con un design naturale. Contattaci per scoprire tutte le personalizzazioni e ricevere un preventivo gratuito.",
    },
    {
      id: "ombrellone",
      materials:
        "struttura: ferro zincato | legno, copertura: erica | stuoia di erica",
      type: "full-product",
      metaTitle: "Ombrellone Lericas - Ombrellone artigianale in erica",
      metaDescription:
        "Gli ombrelloni artigianali Lericas danno quel tocco naturale in più rispetto ai classici ombrelloni in materiali sintetici.",
      images: [
        "ombrellone1",
        "ombrellone2",
        "ombrellone3",
        "ombrellone4",
        "ombrellone5",
        "ombrellone6",
        "ombrellone7",
        "ombrellone8",
        "ombrellone9",
        "ombrellone10",
      ],
      title: "Ombrellone",
      description:
        "Gli ombrelloni artigianali Lericas danno quel tocco naturale in più rispetto ai classici ombrelloni in materiali sintetici. Vieni a scoprire i nostri ombrelloni in erica e saggina, richiedi subito un preventivo!",
    },
    {
      id: "scala",
      materials: "castagno | abete | pino impregnato",
      type: "full-product",
      metaTitle: "Scala Lericas - Scala in legno con materiale della Toscana",
      metaDescription:
        "Le nostre scale in legno sono sicure e si adattano a tutti gli ambienti.",
      images: [
        "scala1",
        "scala2",
        "scala3",
        "scala4",
        "scala5",
        "scala6",
        "scala7",
      ],
      title: "Scala",
      description:
        "Le nostre scale in legno sono sicure e si adattano a tutti gli ambienti. Richiedi subito una delle nostre scale artigianali!",
    },
    {
      id: "staccionata",
      materials: "castagno | pino impregnato",
      type: "full-product",
      metaTitle:
        "Lericas - Staccionata personalizzabile con legni a vostra scelta",
      metaDescription:
        "Le staccionate Lericas sono quello che ci vuole per delimitare e rendere sicuri i tuoi spazi.",
      images: [
        "staccionata1",
        "staccionata2",
        "staccionata3",
        "staccionata4",
        "staccionata5",
        "staccionata6",
      ],
      title: "Staccionata",
      description:
        "Le staccionate in legno Lericas sono quello che ci vuole per delimitare e rendere sicuri i tuoi spazi. Richiedi subito un preventivo gratuito! ",
    },
    {
      id: "recinzione",
      materials: "tutti i tipi di legno su richiesta ( castagno, olivo... )",
      type: "full-product",
      metaTitle:
        "Pannello frangivento Lericas - Pannello frangivento artigianale in legno",
      metaDescription:
        "Le recinzioni Lericas sono ottime per delimitare corridoi e spazi. ",
      images: [
        "recinzione1",
        "recinzione2",
        "recinzione3",
        "recinzione4",
        "recinzione5",
        "recinzione6",
        "recinzione7",
        "recinzione8",
        "recinzione9",
      ],
      title: "Recinzione | Pannello frangivento",
      description:
        "La recinzioni Lericas sono ottime per delimitare corridoi e spazi. Scopri le personalizzazioni per il tuo ambiente richiedendo un preventivo gratuito.",
    },
    {
      id: "cancello",
      materials: "castagno | abete | pino impregnato",
      type: "full-product",
      metaTitle: "Cancello Lericas - Cancello artigianale in legno",
      metaDescription:
        "Ti serve un nuovo cancello artigianale per il tuo nuovo ambiente? Scopri come i nostri cancelli naturali sono la soluzione che stai cercando.",
      images: ["cancello1", "cancello2", "cancello3"],
      title: "Cancello",
      description:
        "Ti serve un nuovo cancello artigianale per il tuo nuovo ambiente? Scopri come i nostri cancelli naturali sono la soluzione che stai cercando. Contattaci per un preventivo gratuito!",
    },
    {
      id: "grigliato",
      materials: "castagno | pino impregnato",
      type: "full-product",
      metaTitle: "Grigliato Lericas - Grigliato artigianale in legno",
      metaDescription:
        "I nostri grigliati in legno sono quello che serve per separare i tuoi spazi. Richiedine subito uno!",
      images: ["grigliato1", "grigliato2", "grigliato3"],
      title: "Grigliato",
      description:
        "I nostri grigliati in legno sono quello che serve per separare i tuoi spazi. Richiedine subito uno! Contattaci per un preventivo gratuito!",
    },
    {
      id: "palizzata",
      materials: "castagno | abete | pino impregnato",
      type: "full-product",
      metaTitle:
        "Palizzata Lericas - Palizzata artigianale in legno - Terrazzamenti",
      metaDescription:
        "Le nostre palizzate in legno naturali sono la soluzione ideale di contenimento per qualsiasi terreno, sia in ambienti marini che rurali.",
      images: [
        "palizzata1",
        "palizzata2",
        "palizzata3",
        "palizzata4",
        "palizzata5",
        "palizzata6",
        "palizzata7",
        "palizzata8",
        "palizzata9",
      ],
      title: "Terrazzamenti",
      description:
        "Le nostre palizzate sono la soluzione ideale di contenimento per qualsiasi terreno, sia in ambienti marini che rurali. Contattaci per un preventivo gratuito!",
    },
    {
      id: "casetta",
      materials: "abete | pino impregnato",
      type: "full-product",
      metaTitle: "Casetta ripostiglio Lericas - Casetta artigianale in legno",
      metaDescription:
        "Le casette naturali, ripostiglio artigianale Lericas sono ottime per riporre i tuoi attrezzi, per la legna oppure in ambiente balneare come spogliatoi.",
      images: ["casetta1", "casetta2", "casetta3", "casetta4", "casetta5"],
      title: "Casetta | Ripostiglio",
      description:
        "Le casette ripostiglio Lericas sono ottime per riporre i tuoi attrezzi, per la legna oppure in ambiente balneare come spogliatoi. Contattaci per avere più informazioni, con un preventivo gratuito!",
    },
    {
      id: "tavolo",
      materials: "castagno | abete | pino impregnato",
      type: "full-product",
      metaTitle: "Tavolo Lericas - Tavolo da pic-nic artigianale",
      metaDescription:
        "I Tavoli da pic-nic lericas sono quello che serve alla tua struttura, con la possibilità di abbinarli ai nostri ombrelloni naturali con coperture in erica. ",
      images: ["tavolo1", "tavolo2", "tavolo3"],
      title: "Tavolo",
      description:
        "I Tavoli da pic-nic lericas sono quello che serve alla tua struttura, con la possibilità di abbinarli ai nostri ombrelloni naturali con coperture in erica. Contattaci per avere altre informazioni e ricevere un preventivo gratuito!",
    },
    {
      id: "arredamento",
      materials: "tutti i tipi di legno su richiesta ( castagno, olivo... )",
      type: "full-product",
      metaTitle: "Arredamenti artigianali e mobili in legno in legno",
      metaDescription:
        "Mobili Naturali, arredamenti artigianali, con Lericas puoi creare un design naturale grazie ai nostri arredamenti naturali!",
      images: ["arredamento1", "arredamento2", "arredamento3", "arredamento4"],
      title: "Arredamenti e rivestimenti",
      description:
        "Vuoi dare un tocco innovativo ai tuoi arredamenti? Con Lericas puoi creare un design naturale grazie ai nostri arredamenti naturali! Contattaci per scoprirne di più e per ricevere un preventivo gratuito!",
    },
    {
      id: "progettazione",
      materials: "",
      type: "service",
      title: "Progettazione",
      image: "design",
    },
    {
      id: "trasporto",
      materials: "",
      type: "service",
      title: "Trasporto",
      image: "transport",
    },
    {
      id: "montaggio",
      materials: "",
      type: "service",
      title: "Montaggio",
      image: "montaggio",
    },
    {
      id: "ricopertura",
      materials: "",
      type: "service",
      title: "Ricopertura",
      image: "ricopertura",
    },
    {
      id: "rinfoltitura",
      materials: "",
      type: "service",
      title: "Rinfoltitura",
      image: "rinfoltitura",
    },
    {
      id: "personalizzazione",
      materials: "",
      type: "service",
      title: "Personalizzazione",
      image: "customization",
    },

    { id: "palo", materials: "castagno | pino impregnato", type: "component" },
    {
      id: "colonna",
      materials: "castagno | pino impregnato",
      type: "component",
    },
    { id: "paracinta", materials: "castagno", type: "component" },
    { id: "trave", materials: "castagno | abete lamellare", type: "component" },
    {
      id: "corrente",
      materials: "castagno | abete lamellare",
      type: "component",
    },
    { id: "fascina", materials: "erica", type: "component" },
    { id: "struscia", materials: "erica", type: "component" },
    { id: "manico", materials: "castagno | faggio", type: "component" },
    {
      id: "stuoia",
      materials: "erica | bambù | willow | cina | canna grezza | corteccia",
      type: "component",
    },
    { id: "listello", materials: "pino impregnato | abete", type: "component" },
    { id: "canne", materials: "bamboo", type: "component" },
    {
      id: "profilato",
      materials: "rame | alluminio | ferro",
      type: "component",
    },
    { id: "impregnante", materials: "", type: "tool" },
    { id: "teloncino", materials: "pvc", type: "tool" },
    { id: "rete ombreggiante", materials: "", type: "tool" },
    { id: "guanti", materials: "", type: "tool" },
    { id: "badile/pala", materials: "plastica | metallo", type: "tool" },
    { id: "piccone", materials: "metallo", type: "tool" },
    { id: "pennello", materials: "", type: "tool" },
    { id: "viteria", materials: "zincate | inox", type: "tool" },
  ],
}
